.rbc-calendar {
    background-color: white;
    width: 100%;
    height: 432px;
    box-shadow: 0px 4px 10px 0px #e9edf0;
    border-radius: 10px;
}

.rbc-month-view {
    border: none;
    padding-bottom: 32px;
}

.rbc-date-cell {
    padding: 0;
}

.rbc-date-cell > div {
    padding: 0
}

.rbc-date-cell > div div {
    padding-left: 0;
}

.rbc-month-row,
.rbc-month-header {
    padding-left: 40px;
    padding-right: 32px;
}

.rbc-month-header .rbc-header {
    padding-left: 8px;
}

.rbc-calendar .rbc-month-view .rbc-header {
    background-color: white;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    height: 32px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rbc-calendar .rbc-row-bg,
.rbc-day-bg,
.rbc-off-range-bg,
.rbc-date-cell {
    border: none;
    height: 100%;
}

.rbc-off-range {
    background: white;
    color: #CDD0D4;
}

.rbc-day-bg {
    padding-left: 8px;
}

.rbc-day-bg + .rbc-day-bg {
    border: none;
}

.rbc-month-row + .rbc-month-row {
    border: none;
}

.rbc-calendar .rbc-month-row {
    height: 100%;
    border-left: none;
    border-right: none;
}

.rbc-calendar .rbc-row-content {
    height: 100%;
}

.rbc-calendar .rbc-row {
    height: 100%;
    max-height: 68px;
}

.rbc-row-bg {
    padding-left: 40px;
    padding-right: 32px;
}

.rbc-calendar .rbc-month-header {
    height: 100%;
    max-height: 32px;
   
}

@media (max-width: 768px) {
    .rbc-month-row,
    .rbc-month-header,
    .rbc-row-bg {
        padding-left: 16px;
        padding-right: 16px;
    }
}
